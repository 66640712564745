import { Button } from "../button/button";
import openEnvelope from "../../assets/lotties/open-envelope.json";
import "./open-red-envelope.scss";
import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import ReactModal from "react-modal";
import axios from "axios";
import { isVideo } from "../../utils/isVideo";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { AdapterState } from "@tronweb3/tronwallet-abstract-adapter";
import redEnvelopeABI from "../../assets/abis/redenvelope.json";
import { checkEnvelopeOpenned } from "../../utils/check-envelope-openned";

export const OpenRedEnvelope = () => {
  const tronWeb = window.tronWeb;

  const [receiverData, setReceiverData] = useState(null);
  const [redEnvelopeOpen, setRedEnvelopeOpen] = useState(false);
  const [showRedEnvelopeQty, setShowRedEnvelopeQty] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [metadata, setMetadata] = useState();
  const [amount, setAmount] = useState();
  const [filesIsVideo, setFilesIsVideo] = useState([]);
  const [wrongNode, setWrongNode] = useState(false);

  const { select, connected, connect, wallets, wallet } = useWallet();

  /* LOADINGS */
  const [commitmentDataLoading, setCommitmentDataLoading] = useState(false);
  const [openRedEnvelopeLoading, setOpenRedEnvelopeLoading] = useState(false);
  const [metadataLoading, setMetadataLoading] = useState(true);

  const RED_ENVELOPE_CONTRACT =
    tronWeb &&
    tronWeb.ready &&
    tronWeb.fullNode.host === process.env.REACT_APP_TRON_RPC &&
    tronWeb.contract(redEnvelopeABI, process.env.REACT_APP_CONTRACT);

  const customStyleModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "2",
      backgroundColor: "#fffbf1",
      border: "2px solid #ceb166",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  useEffect(() => {
    const params = window.location.pathname.split("/");
    if (params.length !== 5) {
      return;
    }
    checkEnvelopeOpenned(params[1], params[2]).then((res) => {
      if (
        res.result.result &&
        res.result.message === "REVERT opcode executed"
      ) {
        setShowModal(true);
        setModalMessage("此信封已收到");
        setRedEnvelopeOpen(true);
        setTimeout(() => {
          setShowRedEnvelopeQty(true);
        }, 6000);
      }
    });

    setAmount(params[4]);
    setReceiverData({ secret: params[1], nullifier: params[2] });
    setCommitmentDataLoading(true);

    if (
      window.tronWeb &&
      window.tronWeb.ready &&
      window.tronWeb.fullNode.host !== process.env.REACT_APP_TRON_RPC
    ) {
      setWrongNode(true);
      setCommitmentDataLoading(false);
      setMetadataLoading(false);
    }

    window.tronWeb &&
      window.tronWeb.ready &&
      window.tronWeb.fullNode.host === process.env.REACT_APP_TRON_RPC &&
      RED_ENVELOPE_CONTRACT["getCommitmentData"](
        ethers.utils.hexZeroPad(
          ethers.utils.hexlify(
            BigNumber.from(window.location.pathname.split("/")[1])
          ),
          32
        ),
        ethers.utils.hexZeroPad(
          ethers.utils.hexlify(
            BigNumber.from(window.location.pathname.split("/")[2])
          ),
          32
        )
      )
        .call()
        .then((data) => {
          if (
            data === ethers.utils.keccak256(ethers.utils.toUtf8Bytes(params[3]))
          ) {
            axios
              .get(
                `https://salmon-protestant-armadillo-697.mypinata.cloud/ipfs/${params[3]}`
              )
              .then((res) => {
                setMetadata(res.data);
                res.data.multimedia.length > 0 &&
                  res.data.multimedia.forEach(async (item) => {
                    const itemIsVideo = await isVideo(
                      `https://salmon-protestant-armadillo-697.mypinata.cloud/ipfs/${item}`
                    );
                    setFilesIsVideo((prevState) => [
                      ...prevState,
                      { cid: item, isVideo: itemIsVideo },
                    ]);
                  });
              });
            setMetadataLoading(false);
          }
          setCommitmentDataLoading(false);
        });
  }, [window.tronWeb]);

  const handleConnect = async (wallet) => {
    select(wallet.adapter.name);
    setShowModal(false);
    setShowConnectModal(false);
  };

  const openRedEnvelope = async () => {
    try {
      setOpenRedEnvelopeLoading(true);
      await RED_ENVELOPE_CONTRACT["accept"](
        ethers.utils.hexZeroPad(
          ethers.utils.hexlify(BigNumber.from(receiverData.secret)),
          32
        ),
        ethers.utils.hexZeroPad(
          ethers.utils.hexlify(BigNumber.from(receiverData.nullifier)),
          32
        )
      ).send({
        feeLimit: 100_000_000,
        callValue: 0,
        shouldPollResponse: false,
      });

      setOpenRedEnvelopeLoading(false);
      setRedEnvelopeOpen(true);
      setTimeout(() => {
        setShowRedEnvelopeQty(true);
      }, 6000);
    } catch (error) {
      setOpenRedEnvelopeLoading(false);
      setShowModal(true);
      setModalMessage(error);
    }
  };

  if (commitmentDataLoading || metadataLoading) {
    return (
      <div className="center">
        <h1>加载中…</h1>
      </div>
    );
  }

  return (
    <>
      <div className="success_container_open_screen">
        {redEnvelopeOpen ? (
          <>
            <div className="row multimedia_container">
              {filesIsVideo.map((item) => {
                return item.isVideo ? (
                  <video
                    key={item.cid}
                    src={`https://salmon-protestant-armadillo-697.mypinata.cloud/ipfs/${item.cid}`}
                    alt=""
                    controls
                  />
                ) : (
                  <img
                    key={item.cid}
                    src={`https://salmon-protestant-armadillo-697.mypinata.cloud/ipfs/${item.cid}`}
                    alt=""
                  />
                );
              })}
            </div>
            <div className="lottie_container">
              <Lottie
                animationData={openEnvelope}
                style={{
                  height: 400,
                }}
                loop={0}
              />
              <span className={showRedEnvelopeQty ? "show_metadata" : ""}>
                ${amount}
              </span>
              <h2 className={showRedEnvelopeQty ? "show_metadata" : ""}>
                {metadata?.title}
              </h2>
              <p className={showRedEnvelopeQty ? "show_metadata" : ""}>
                {metadata?.message}
              </p>
            </div>
          </>
        ) : (
          <img src="/assets/images/red_envelope_default.png" alt="" />
        )}

        <div className="button_container_open_envelope">
          {!window.tronWeb ? (
            <p className="text-center primary_button">
              请安装 tronlink 扩展登录
            </p>
          ) : wrongNode ? (
            <p className="text-center primary_button">
              TronLink connected to wrong network
              <br />
              change to {process.env.REACT_APP_TRON_NETWORK} network
            </p>
          ) : !wallet ? (
            <Button
              classStyleName="primary_button"
              text="选择钱包" //SELECT WALLET
              handleOnClick={() => setShowConnectModal(true)}
            />
          ) : !connected ? (
            <div className="connect-buttons-container">
              {" "}
              <Button
                classStyleName="primary_button"
                text="连接" //CONNECT
                handleOnClick={() => connect()}
              />
              <Button
                classStyleName="primary_button"
                text="选择钱包" //SELECT WALLET
                handleOnClick={() => setShowConnectModal(true)}
              />
            </div>
          ) : (
            <Button
              classStyleName={"primary_button"}
              text={openRedEnvelopeLoading ? "加载中…" : "立即打開"} //LOADING… / ACCEPT
              handleOnClick={() => {
                !openRedEnvelopeLoading &&
                  !redEnvelopeOpen &&
                  openRedEnvelope();
              }}
            />
          )}
        </div>
      </div>
      <ReactModal isOpen={showModal} style={customStyleModal}>
        <p>{modalMessage}</p>
        <div className="buton_container_modal">
          <Button
            text={"关闭"} //CLOSE
            classStyleName={"primary_button"}
            handleOnClick={() => {
              setShowModal(false);
            }}
          />
        </div>
      </ReactModal>
      <ReactModal isOpen={showConnectModal} style={customStyleModal}>
        <div className="modal-header">
          <p>在 tron 上连接钱包</p> {/* Connect a wallet on Tron */}
          <img
            onClick={() => setShowConnectModal(false)}
            src="/assets/images/delete_icon.png"
            alt=""
          />
        </div>
        <div className="buton_container_modal">
          {wallets.map((wallet) => {
            return (
              <Button
                key={wallet.adapter.name}
                classStyleName={"primary_button"}
                handleOnClick={() => handleConnect(wallet)}
              >
                <img width={"30px"} src={wallet.adapter.icon} alt="" />
                {wallet.adapter.name}
                <span className="status-text">
                  {wallet.state !== AdapterState.NotFound
                    ? "Detected"
                    : "NotFound"}
                </span>
              </Button>
            );
          })}
        </div>
      </ReactModal>
    </>
  );
};
