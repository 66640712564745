import { ShowETHAddress } from "../../utils/show_eth_address";
import "./wallet-dropdown.scss";
import toast from "react-hot-toast";
import { Button } from "../button/button";
import { useEffect, useState } from "react";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { ethers } from "ethers";

import redEnvelopeABI from "../../assets/abis/redenvelope.json";

export const WalletDropdown = ({ open }) => {
  const tronWeb = window.tronWeb;
  const { disconnect, address } = useWallet();

  const RED_ENVELOPE_CONTRACT =
    tronWeb &&
    tronWeb.ready &&
    tronWeb.fullNode.host === process.env.REACT_APP_TRON_RPC &&
    tronWeb.contract(redEnvelopeABI, process.env.REACT_APP_CONTRACT);

  /* LOADINGS */
  const [referrerAmountIsLoading, setReferrerAmountIsLoading] = useState(true);
  const [withdrawRewardsLoading, setWithdrawRewardsLoading] = useState(false);
  const [faqsOpen, setFAQSOpen] = useState(false);

  const [referrerAmount, setReferrerAmount] = useState();
  const REFERRER_LINK = {
    TO_SHOW: process.env.REACT_APP_WEB_URL + ShowETHAddress(address),
    TO_COPY: process.env.REACT_APP_WEB_URL + address,
  };

  const notifyCopiedLink = () => toast.success("复制的"); //COPIED
  const handleDisconnect = () => {
    disconnect();
    document.getElementById("hide-dropdown").click();
  };

  const openFAQs = (e) => {
    e.preventDefault();
    setFAQSOpen(true);
  };

  useEffect(() => {
    if (
      (tronWeb &&
        tronWeb.ready &&
        tronWeb.fullNode.host !== process.env.REACT_APP_TRON_RPC) ||
      !tronWeb ||
      (tronWeb && !tronWeb.ready)
    ) {
      setReferrerAmountIsLoading(false);
      setReferrerAmount(0.0);
    }

    tronWeb &&
      tronWeb.ready &&
      tronWeb.fullNode.host === process.env.REACT_APP_TRON_RPC &&
      tronWeb
        .contract(redEnvelopeABI, process.env.REACT_APP_CONTRACT)
        ["getRewardBalance"](address)
        .call()
        .then((data) => {
          setReferrerAmount(
            ethers.utils.formatUnits(
              data.toString(),
              parseInt(process.env.REACT_APP_TOKEN_DECIMALS)
            )
          );
          setReferrerAmountIsLoading(false);
        });
  }, []);

  const withdrawRewards = () => {
    setWithdrawRewardsLoading(true);
    RED_ENVELOPE_CONTRACT["withdrawRewards"]()
      .send({
        feeLimit: 100_000_000,
        callValue: 0,
        shouldPollResponse: true,
      })
      .then(() => {
        setReferrerAmount(0.0);
        setWithdrawRewardsLoading(false);
      });
  };

  if (open && faqsOpen) {
    return (
      <div className="dropdown">
       <p><strong>步骤1：钱包连接</strong> 用户必须连接其与Tron网络（TRC20）兼容的加密货币钱包。这种连接是必需的，以认证用户并允许他们进行交易。</p>
       <p><strong>步骤2：选择金额和发送</strong> 发送的金额是固定的，以保持发送者和接收者钱包的匿名性，以及在TRC20网络上的USDT货币。</p>
       <p><strong>步骤3：定制红包</strong> 用户可以选择性地向数字红包添加文本和多媒体内容。</p>
        <p><strong>步骤4：创建和分发红包</strong> 创建红包时，将生成一个独特的链接，并下载一个包含此链接的.txt文件。 安全警告：如果链接丢失，将无法恢复资金。</p>
        <p><strong>步骤5：接收和打开红包</strong> 接收者可以随时通过链接打开红包。 多媒体内容可以随时通过提供的链接访问和下载。</p>
        <p><strong>额外考虑</strong> 发送者和接收者都必须在他们的钱包中有TRX，以支付网络费用和佣金。</p>
        <p><strong>费用：</strong>平台向接收者收取1%的佣金，另外还有1%的推荐系统费用。</p>
        <br/>
        <p><strong>推荐系统</strong></p>
        <ul>
          <li><strong>参与推荐系统的资格</strong> 要访问推荐系统，用户首先必须创建至少一个红包。</li>
          <li><strong>推荐链接的访问和使用</strong> 在web-app中，点击钱包按钮后，会展开一个菜单，用户可以在其中找到他们的推荐链接。</li>
          <li><strong>推荐系统的好处</strong></li>
          <ul>
            <li>当用户打开一个由你推荐的人创建的红包时，你将累积该红包中USDT的1%。你可以随时将其提现到你的钱包。</li>
            <li>如果你的推荐人也有自己的推荐人，那么最后的推荐人将收到交易的1%。然而，当你的直接推荐人将其推荐人累积的佣金提现到他们的钱包时，你将收到该金额1%的累积。</li>
            <li>如果你被另一个用户推荐，当你提现你的累积金额时，99%的金额将存入你的钱包，剩下的1%将添加到你的推荐人的余额中，准备由他提现。</li>
          </ul>
        </ul>
        <button class="primary_button" onClick={() => setFAQSOpen(false)}>关闭</button>
      </div>
    );
  }

  return (
    open && (
      <div className="dropdown">
        <p>Wallet</p>
        <hr />
        <p>Referrer Link:</p>
        <div
          className="dropdown_cell"
          onClick={() => {
            navigator.clipboard.writeText(REFERRER_LINK.TO_COPY);
            notifyCopiedLink();
          }}
        >
          <p className="referrer_link">{REFERRER_LINK.TO_SHOW}</p>
          <img src="/assets/images/copy-icon.png" alt="" />
        </div>
        <hr />
        <div className="dropdown_cell referrer_container">
          <p>
            Referrer Amount: $
            {referrerAmountIsLoading ? "加载中…" : referrerAmount}
          </p>
          {referrerAmount > 0 && (
            <Button
              text={withdrawRewardsLoading ? "加载中…" : "Withdraw"}
              classStyleName={"primary_button"}
              handleOnClick={() => withdrawRewards()}
            />
          )}
        </div>

        <hr />

        <p><a href="#" onClick={openFAQs}>FAQs</a></p>
        <hr />
        <div className="dropdown_cell" onClick={() => handleDisconnect()}>
          {" "}
          <p>Disconect</p>
          <img src="/assets/images/logout-icon.png" alt="" />
        </div>
      </div>
    )
  );
};
