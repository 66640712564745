import { create } from "ipfs-http-client";
import axios from "axios";

const projectId = "<YOUR PROJECT ID>";
const projectSecret = "<YOUR PROJECT SECRET>";
const authorization = "Basic " + btoa(projectId + ":" + projectSecret);

const ipfs = create({
  url: "https://ipfs.infura.io:5001/api/v0",
  headers: {
    authorization,
  },
});

export const addToIpfs = async (file) => {
  const result = await ipfs.add(file);
  return { CID: result.cid, PATH: result.path };
};

export const addFileToIpfsPinata = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const resFile = await axios.post(
      "https://api.pinata.cloud/pinning/pinFileToIPFS",
      formData,
      {
        maxContentLength: "Infinity",
        headers: {
          pinata_api_key: `${process.env.REACT_APP_PINATA_API_KEY}`,
          pinata_secret_api_key: `${process.env.REACT_APP_PINATA_API_SECRET}`,
        },
      }
    );

    return resFile.data.IpfsHash;
  } catch (error) {
    console.log("Error sending File to IPFS: ");
    console.log(error);
  }
};

export const addJsonToIpfsPinata = async (json) => {
  try {
    const resFile = await axios.post(
      "https://api.pinata.cloud/pinning/pinJSONToIPFS",
      JSON.stringify({
        pinataMetadata: { name: "Red Envelope Metadata" },
        pinataContent: json,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_API_SECRET,
        },
      }
    );

    return resFile.data.IpfsHash;
  } catch (error) {
    console.log("Error sending File to IPFS: ");
    console.log(error);
  }
};
