import { useEffect, useState } from "react";
import { Button } from "../button/button";
import { lanternObject } from "../../utils/lanterns";
import "./step-two.scss";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import ReactModal from "react-modal";
import { AdapterState } from "@tronweb3/tronwallet-abstract-adapter";

export const StepTwo = ({
  handleSetLanternSelected,
  lanternSelected,
  handleSetStep,
}) => {
  const { select, connected, connect, wallets, wallet } = useWallet();
  const [showModal, setShowModal] = useState(false);
  const [wrongNode, setWrongNode] = useState(false);

  const customStyleModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "2",
      backgroundColor: "#fffbf1",
      border: "2px solid #ceb166",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30%",
    },
  };

  useEffect(() => {
    if (!connected && wallet && wallet.state === "Disconnected") {
      setShowModal(true);
    }

    if (
      window.tronWeb &&
      window.tronWeb.ready &&
      window.tronWeb.fullNode.host !== process.env.REACT_APP_TRON_RPC
    ) {
      setWrongNode(true);
    }
    !connected && handleSetLanternSelected("");
  }, [connected, wallet]);

  const handleConnect = (wallet) => {
    try {
      select(wallet.adapter.name);
      setShowModal(false);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <div className="row main_content">
        <div className="col-lg-5 col-md-4">
          <div className="lantern_container_left">
            <div className="lantern_wrap lantern_wrap_1">
              <button
                onClick={() =>
                  handleSetLanternSelected(lanternObject.LANTERN_1)
                }
              >
                <p
                  className={
                    lanternSelected === lanternObject.LANTERN_1
                      ? "text-bold"
                      : ""
                  }
                >
                  <span>$</span>
                  {lanternObject.LANTERN_1}
                </p>
                <div
                  className={
                    lanternSelected === lanternObject.LANTERN_1
                      ? "lantern-selected show-lantern"
                      : "lantern-selected"
                  }
                ></div>
                <img
                  className="lantern_img lantern-1"
                  src="/assets/images/lantern.png"
                  alt=""
                />
              </button>
            </div>

            <div className="lantern_wrap lantern_wrap_10">
              <button
                onClick={() =>
                  handleSetLanternSelected(lanternObject.LANTERN_10)
                }
              >
                <p
                  className={
                    lanternSelected === lanternObject.LANTERN_10
                      ? "text-bold"
                      : ""
                  }
                >
                  <span>$</span>
                  {lanternObject.LANTERN_10}
                </p>
                <div
                  className={
                    lanternSelected === lanternObject.LANTERN_10
                      ? "lantern-selected show-lantern"
                      : "lantern-selected"
                  }
                ></div>
                <img
                  className="lantern_img lantern-10"
                  src="/assets/images/lantern.png"
                  alt=""
                />
              </button>
            </div>

            <div className="lantern_wrap lantern_wrap_100">
              <button
                onClick={() =>
                  handleSetLanternSelected(lanternObject.LANTERN_100)
                }
              >
                <p
                  className={
                    lanternSelected === lanternObject.LANTERN_100
                      ? "text-bold"
                      : ""
                  }
                >
                  <span>$</span>
                  {lanternObject.LANTERN_100}
                </p>
                <div
                  className={
                    lanternSelected === lanternObject.LANTERN_100
                      ? "lantern-selected show-lantern"
                      : "lantern-selected"
                  }
                ></div>
                <img
                  className="lantern_img lantern-100"
                  src="/assets/images/lantern.png"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 center">
          <div className="envelope_container">
            <img
              className="envelope_img"
              src="/assets/images/red_envelope_default.png"
              alt=""
            />

            {lanternSelected && (
              <>
                <h3>
                  <span>$</span>
                  {lanternSelected}
                </h3>
                <img
                  className="amount_frame"
                  src="/assets/images/amount_frame.png"
                  alt=""
                />
              </>
            )}
          </div>
        </div>
        <div className="col-lg-5 col-md-4">
          <div className="lantern_container_right">
            <div className="lantern_wrap lantern_wrap_1000">
              <button
                onClick={() =>
                  handleSetLanternSelected(lanternObject.LANTERN_1000)
                }
              >
                <p
                  className={
                    lanternSelected === lanternObject.LANTERN_1000
                      ? "text-bold"
                      : ""
                  }
                >
                  <span>$</span>
                  {lanternObject.LANTERN_1000}
                </p>
                <div
                  className={
                    lanternSelected === lanternObject.LANTERN_1000
                      ? "lantern-selected show-lantern"
                      : "lantern-selected"
                  }
                ></div>
                <img
                  className="lantern_img lantern-1"
                  src="/assets/images/lantern.png"
                  alt=""
                />
              </button>
            </div>

            <div className="lantern_wrap lantern_wrap_10000">
              <button
                onClick={() =>
                  handleSetLanternSelected(lanternObject.LANTERN_10000)
                }
              >
                <p
                  className={
                    lanternSelected === lanternObject.LANTERN_10000
                      ? "text-bold"
                      : ""
                  }
                >
                  <span>$</span>
                  {lanternObject.LANTERN_10000}
                </p>
                <div
                  className={
                    lanternSelected === lanternObject.LANTERN_10000
                      ? "lantern-selected show-lantern"
                      : "lantern-selected"
                  }
                ></div>
                <img
                  className="lantern_img lantern-10"
                  src="/assets/images/lantern.png"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row save_lantern_amount_button_container">
        <div className="col center">
          {!window.tronWeb ? (
            <p className="text-center primary_button">
              请安装 tronlink 扩展登录
            </p>
          ) : wrongNode ? (
            <p className="text-center primary_button">
              TronLink connected to wrong network
              <br />
              change to {process.env.REACT_APP_TRON_NETWORK} network
            </p>
          ) : !wallet ? (
            <Button
              classStyleName="primary_button"
              text="选择钱包" //SELECT WALLET
              handleOnClick={() => setShowModal(true)}
            />
          ) : !connected ? (
            <div className="connect-buttons-container">
              {" "}
              <Button
                classStyleName="primary_button"
                text="连接" //CONNECT
                handleOnClick={() => connect()}
              />
              <Button
                classStyleName="primary_button"
                text="选择钱包" //SELECT WALLET
                handleOnClick={() => setShowModal(true)}
              />
            </div>
          ) : (
            <Button
              classStyleName="primary_button"
              text="保存"
              /* SAVE */ handleOnClick={() =>
                lanternSelected && handleSetStep("stepThree")
              }
            />
          )}
        </div>
      </div>
      <ReactModal isOpen={showModal} style={customStyleModal}>
        <div className="modal-header">
          <p>在 tron 上连接钱包</p> {/* Connect a wallet on Tron */}
          <img
            onClick={() => setShowModal(false)}
            src="/assets/images/delete_icon.png"
            alt=""
          />
        </div>
        <div className="buton_container_modal">
          {wallets.map((wallet) => {
            return (
              <Button
                key={wallet.adapter.name}
                classStyleName={"primary_button connect-modal-button"}
                handleOnClick={() => handleConnect(wallet)}
              >
                <img width={"30px"} src={wallet.adapter.icon} alt="" />
                {wallet.adapter.name}
                <span className="status-text">
                  {wallet.state !== AdapterState.NotFound
                    ? "Detected"
                    : "Not Found"}
                </span>
              </Button>
            );
          })}
        </div>
      </ReactModal>
    </>
  );
};
