import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { WalletProvider } from "@tronweb3/tronwallet-adapter-react-hooks";
import {
  WalletDisconnectedError,
  WalletNotFoundError,
  WalletConnectionError,
  WalletDisconnectionError,
  WalletNotSelectedError,
  WalletWalletLoadError,
  WalletWindowClosedError,
} from "@tronweb3/tronwallet-abstract-adapter";
import {
  BitKeepAdapter,
  LedgerAdapter,
  OkxWalletAdapter,
  TokenPocketAdapter,
  TronLinkAdapter,
} from "@tronweb3/tronwallet-adapters";
import { WalletConnectAdapter } from "@tronweb3/tronwallet-adapter-walletconnect";
import toast from "react-hot-toast";

const adapters = [
  new TronLinkAdapter(),
  new WalletConnectAdapter({
    network: "Nile",
    options: {
      relayUrl: "wss://relay.walletconnect.com",
      // example WC app project ID
      projectId: "29d8fb84b9b53a22b8874c8d37f55d34",
      metadata: {
        name: "Red Envelope App",
        description: "Send Red Envelopes",
        url: "https://your-dapp-url.org/",
        icons: ["../public/assets/images/logo.png"],
      },
    },
    web3ModalConfig: {
      themeMode: "dark",
      themeVariables: {
        "--w3m-z-index": "1000",
      },
    },
  }),
  new LedgerAdapter({
    accountNumber: 2,
  }),
  new BitKeepAdapter(),
  new TokenPocketAdapter(),
  new OkxWalletAdapter(),
];

function onError(e) {
  if (e instanceof WalletNotFoundError) {
    toast.error(e.message);
  } else if (e instanceof WalletDisconnectedError) {
    toast.error(e.message);
  } else if (e instanceof WalletConnectionError) {
    toast.error(e.message);
  } else if (e instanceof WalletDisconnectionError) {
    toast.error(e.message);
  } else if (e instanceof WalletNotSelectedError) {
    toast.error(e.message);
  } else if (e instanceof WalletWalletLoadError) {
    toast.error(e.message);
  } else if (e instanceof WalletWindowClosedError) {
    toast.error(e.message);
  } else toast.error(e.message);
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <WalletProvider
    onError={onError}
    autoConnect={true}
    disableAutoConnectOnLoad={true}
    adapters={adapters}
  >
    <App />
  </WalletProvider>
);
