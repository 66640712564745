import { BigNumber } from "ethers";

const randomBytes = (nbytes) => {
  const array = new Uint32Array(nbytes);
  crypto.getRandomValues(array);
  return Buffer.from(array).toString("hex");
};

export const randomBigint = (nbytes) => {
  return BigNumber.from("0x" + randomBytes(nbytes) + "0");
};
