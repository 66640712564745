import { useState } from "react";
import "./step-three.scss";
import { Button } from "../button/button";
import { getThumbnailFromFile } from "../../utils/getThumbnailFromFile";
import { getVideoDuration } from "../../utils/getVideoDuration";
import {
  addFileToIpfsPinata,
  addJsonToIpfsPinata,
} from "../../utils/addToIpfs";

export const StepThree = ({ handleSetStep, handleSetMetadataIpfsHash }) => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [itemSelected, setItemSelected] = useState("");
  const [multimedia, setMultimedia] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const titles = ["新年快乐", "恭喜发财", "平安喜乐", "大吉大利", "龙年大吉"];

  const removeItemFromFileList = (i) => {
    const filesArray = Array.from(multimedia);
    filesArray.splice(i, 1);
    filesArray.length === 0 ? setMultimedia() : setMultimedia(filesArray);
  };

  const addMetadataToIpfs = async () => {
    try {
      setIsLoading(true);
      const multimediaIpfsHashes = [];

      if (multimedia) {
        const multimediaArray = Array.from(multimedia);
        for (let i = 0; i < multimediaArray.length; i++) {
          const hash = await addFileToIpfsPinata(multimediaArray[i]);
          multimediaIpfsHashes.push(hash);
        }
      }

      const metadata = {
        title,
        message,
        multimedia: multimediaIpfsHashes,
      };

      const finalIpfsHash = await addJsonToIpfsPinata(metadata);
      handleSetMetadataIpfsHash(finalIpfsHash);

      setIsLoading(false);

      handleSetStep("stepFour");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div className="add_multimedia_container">
      <div className="info_container">
        <img
          className="background"
          src="/assets/images/step-three-bg.png"
          alt=""
        />
        <img
          className="corner corner-top-left"
          src="/assets/images/corner.png"
          alt=""
        />
        <img
          className="corner corner-bottom-left"
          src="/assets/images/corner.png"
          alt=""
        />
        <img
          className="corner corner-top-right"
          src="/assets/images/corner.png"
          alt=""
        />
        <img
          className="corner corner-bottom-right"
          src="/assets/images/corner.png"
          alt=""
        />
        <h2>新年祝福语(红包封面文字)</h2>
        <div className="select-title mt-3">
          {titles.map((title, i) => {
            return (
              <button
                key={i}
                className={
                  itemSelected === i
                    ? "primary_button_outline_title selected"
                    : "primary_button_outline_title"
                }
                onClick={() => {
                  setTitle(title);
                  setItemSelected(i);
                }}
              >
                {title}
              </button>
            );
          })}
        </div>
        <h2 className="mt-4">您的信息(100字内)</h2>
        <textarea
          className="mt-3"
          placeholder="请在此输入您的信息"
          onChange={(e) => setMessage(e.target.value)}
        />

        {multimedia && (
          <div className="multimedia_container center">
            <div className="files_container">
              {Array.from(multimedia).map((file, i) => {
                return (
                  <div key={i} className="file">
                    <img
                      id={file.name + i}
                      className="preview_image"
                      src={getThumbnailFromFile(file, i)}
                      alt=""
                    />
                    <p>
                      {file.name.length > 15
                        ? file.name.substring(0, 15) +
                          "… " +
                          file.name.substring(
                            file.name.length,
                            file.name.length - 4
                          )
                        : file.name}{" "}
                      {file.type.substring(0, 5) === "video" && (
                        <span id="video_duration">
                          {getVideoDuration(file)}
                        </span>
                      )}{" "}
                      {(file.size / (1 * 10 ** 6)).toFixed(2) + "Mb"}
                    </p>
                    <img
                      className="delete_icon"
                      src="/assets/images/delete_icon.png"
                      alt=""
                      onClick={() => removeItemFromFileList(i)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <input
          className="select_multimedia"
          type="file"
          name=""
          id="select_files_id"
          multiple
          onChange={(e) => setMultimedia(e.target.files)}
        />

        <div
          className={multimedia ? "button_container" : "button_container mt-3"}
        >
          <Button
            text={"上传多媒体文件"}
            classStyleName={"primary_button_outline"}
            handleOnClick={() =>
              document.getElementById("select_files_id").click()
            }
          />
        </div>
      </div>

      <div className="save_multimedia_button_container center">
        <Button
          classStyleName="primary_button"
          text={isLoading ? "加载中…" : "保存"}
          /* SAVE */ handleOnClick={() => addMetadataToIpfs()}
        />
      </div>
    </div>
  );
};
