import { BigNumber, ethers } from "ethers";

export const checkEnvelopeOpenned = async (secret, nullifier) => {
  const abiCoder = new ethers.utils.AbiCoder();

  const commitment = [
    ethers.utils.hexZeroPad(ethers.utils.hexlify(BigNumber.from(secret)), 32),
    ethers.utils.hexZeroPad(
      ethers.utils.hexlify(BigNumber.from(nullifier)),
      32
    ),
  ];

  const params = abiCoder
    .encode(["bytes32", "bytes32"], commitment)
    .replace(/^(0x)/, "");

  const options = {
    method: "POST",
    headers: { accept: "application/json", "content-type": "application/json" },
    body: JSON.stringify({
      owner_address: "TBE5oNNKLfFebhQaYAdi8kQi4W7dFVRmi5",
      contract_address: process.env.REACT_APP_CONTRACT,
      function_selector: "accept(bytes32,bytes32)",
      parameter: params,
      visible: true,
    }),
  };

  const res = await fetch(
    "https://nile.trongrid.io/wallet/triggerconstantcontract",
    options
  );
  const data = await res.json();
  return data;
};
