export const isVideo = (mediaUrl) => {
  return new Promise((resolve) => {
    (async () => {
      const response = await fetch(mediaUrl);
      const fileType = response.headers.get("Content-Type");
      if (fileType && fileType.substring(0, 5) === "video") {
        resolve(true);
      } else {
        resolve(false);
      }
    })();
  });
};
