import React, { useEffect, useState } from "react";
import "./App.scss";
import { Toaster } from "react-hot-toast";
import { StepTwo } from "./components/step-two/step-two";
import { Header } from "./components/header/header";
import { StepThree } from "./components/step-three/step-three";
import { StepFour } from "./components/step-four/step-four";
import { StepFive } from "./components/step-five/step-five";
import { OpenRedEnvelope } from "./components/open-red-envelope/open-red-envelope";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";

function App() {
  const { connected } = useWallet();
  const [step, setStep] = useState("stepOne");
  const [lanternselected, setLanternSelected] = useState();
  const [receiverData, setReceiverData] = useState();
  const [metadataIpfsHash, setMetadataIpfsHash] = useState();

  useEffect(() => {
    window.addEventListener("message", function (e) {
      if (e.data.message && e.data.message.action === "setNode") {
        window.location.reload();
      }
    });

    if (connected) {
      setStep("stepTwo");
    } else {
      setStep("stepOne");
    }
  }, []);

  useEffect(() => {
    const params = window.location.pathname.split("/");
    if (params.length !== 5) {
      return;
    }

    setReceiverData({ secret: params[1], nullifier: params[2] });
  }, []);

  return (
    <div
      className={
        step === "stepFour"
          ? "main_container bg_white_bottom_space"
          : "main_container"
      }
    >
      <Toaster />
      <Header step={step} handleSetStep={setStep} />
      {receiverData ? (
        <OpenRedEnvelope />
      ) : (
        <>
          {" "}
          {(step === "stepOne" || step === "stepTwo") && (
            <StepTwo
              handleSetStep={setStep}
              handleSetLanternSelected={setLanternSelected}
              lanternSelected={lanternselected}
            />
          )}
          {step === "stepThree" && (
            <StepThree
              handleSetStep={setStep}
              handleSetMetadataIpfsHash={setMetadataIpfsHash}
            />
          )}
          {step === "stepFour" && (
            <StepFour
              handleSetStep={setStep}
              lanternSelected={lanternselected}
              metadataIpfsHash={metadataIpfsHash}
            />
          )}
          {step === "stepFive" && (
            <StepFive
              handleSetStep={setStep}
              handleSetLanternSelected={setLanternSelected}
            />
          )}
        </>
      )}
    </div>
  );
}

export default App;
