import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { randomBigint } from "../../utils/randomBytes";
/* LOTTIES FILES */
import red_envelope_long from "../../assets/lotties/red_envelope_long.json";
import red_envelope_orange from "../../assets/lotties/red_envelope_orange.json";
import red_envelope_rich from "../../assets/lotties/red_envelope_rich.json";
import red_envelope_safe from "../../assets/lotties/red_envelope_safe.json";

import "./step-four.scss";
import { Button } from "../button/button";
import { ethers } from "ethers";
import { createAndSaveTXT } from "../../utils/createAndSaveTXT";
import ReactModal from "react-modal";
import toast from "react-hot-toast";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import redEnvelopeABI from "../../assets/abis/redenvelope.json";
import erc20ABI from "../../assets/abis/erc20.json";

export const StepFour = ({
  handleSetStep,
  lanternSelected,
  metadataIpfsHash,
}) => {
  const tronWeb = window.tronWeb;

  const [redEnvelopeSelected, setRedEnvelopeSelected] = useState("default");
  const [secret, setSecret] = useState();
  const [commitment, setCommitment] = useState();
  const [amount, setAmount] = useState();
  const [referrerData, setReferrerData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [urlRedEnvelope, setUrlRedEnvelope] = useState();
  const [readTerms, setReadTerms] = useState(false);
  const [warningToAccept, setWarningToAccept] = useState(false);
  const notifyCopiedLink = () => toast.success("复制的"); //COPIED
  const [sufficientAllowance, setSufficientAllowance] = useState(false);

  /* LOADINGS */
  const [allowanceTokenLoading, setAllowanceTokenLoading] = useState(true);
  const [approveTokenLoading, setApproveTokenLoading] = useState(false);
  const [createRedEnvelopeLoading, setCreateRedEnvelopeLoading] =
    useState(false);

  const TOKEN_CONTRACT =
    tronWeb &&
    tronWeb.ready &&
    tronWeb.fullNode.host === process.env.REACT_APP_TRON_RPC &&
    tronWeb.contract(erc20ABI, process.env.REACT_APP_TOKEN_CONTRACT);

  const RED_ENVELOPE_CONTRACT =
    tronWeb &&
    tronWeb.ready &&
    tronWeb.fullNode.host === process.env.REACT_APP_TRON_RPC &&
    tronWeb.contract(redEnvelopeABI, process.env.REACT_APP_CONTRACT);

  const { address } = useWallet();

  const MAX_INT =
    "115792089237316195423570985008687907853269984665640564039457584007913129639935";

  const customStyleModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",

      backgroundColor: "#fffbf1",
      border: "2px solid #ceb166",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  const defaultStyle = {
    height: 350,
  };
  const smallStyle = {
    height: 220,
  };
  const longStyle = {
    height: 250,
  };
  const ENVELOPES_STYLES = [
    { id: "rich", animationId: red_envelope_rich, styleId: smallStyle },
    { id: "long", animationId: red_envelope_long, styleId: longStyle },
    { id: "orange", animationId: red_envelope_orange, styleId: smallStyle },
    { id: "safe", animationId: red_envelope_safe, styleId: smallStyle },
  ];

  useEffect(() => {
    window.tronWeb &&
      window.tronWeb.ready &&
      window.tronWeb.fullNode.host === process.env.REACT_APP_TRON_RPC &&
      TOKEN_CONTRACT["allowance"](address, process.env.REACT_APP_CONTRACT)
        .call()
        .then((data) => {
          if (
            data.toString() !== "0" &&
            parseInt(data.toString()) >= parseInt(lanternSelected)
          ) {
            setSufficientAllowance(true);
          }
          setAllowanceTokenLoading(false);
        });

    setAmount(
      ethers.utils
        .parseUnits(
          lanternSelected.toString(),
          parseInt(process.env.REACT_APP_TOKEN_DECIMALS)
        )
        .toString()
    );

    const secret = randomBigint(31);
    const nullifier = randomBigint(31);

    setSecret({ secret, nullifier });

    setCommitment(
      ethers.utils.keccak256(
        ethers.utils.defaultAbiCoder.encode(
          ["bytes32", "bytes32"],
          [secret, nullifier]
        )
      )
    );

    const params = window.location.pathname.split("/");
    if (params.length !== 2) {
      return;
    }
    if (ethers.utils.isAddress(params[1])) {
      setReferrerData(params[1]);
    }
  }, [window.tronWeb]);

  const createUrlAndShow = () => {
    const redEnvelopeURL =
      window.location.protocol +
      "//" +
      window.location.host +
      "/" +
      secret.secret +
      "/" +
      secret.nullifier +
      "/" +
      metadataIpfsHash +
      "/" +
      lanternSelected;
    setUrlRedEnvelope(redEnvelopeURL);
    setModalMessage("以下是红包打开链接，请妥善保管，切勿丢失：");
    setShowModal(true);
    createAndSaveTXT(redEnvelopeURL);
  };

  const approveToken = async () => {
    try {
      setApproveTokenLoading(true);
      await TOKEN_CONTRACT["approve"](
        process.env.REACT_APP_CONTRACT,
        MAX_INT
      ).send({
        feeLimit: 100_000_000,
        callValue: 0,
        shouldPollResponse: false,
      });
      setSufficientAllowance(true);
      setApproveTokenLoading(false);
    } catch (error) {
      if (error.error === "CONTRACT_VALIDATE_ERROR") {
        toast.error("Insufficient balance");
      } else {
        toast.error("Transaction rejected by user");
      }

      setApproveTokenLoading(false);
    }
  };

  const createRedEnvelope = async () => {
    try {
      setCreateRedEnvelopeLoading(true);

      const balance = await TOKEN_CONTRACT["balanceOf"](address).call();

      if (parseInt(balance.toString()) < parseInt(lanternSelected)) {
        toast.error("Insuficient token balance");
        setCreateRedEnvelopeLoading(false);
      } else {
        RED_ENVELOPE_CONTRACT["send"](
          amount,
          commitment,
          referrerData ? referrerData : ethers.constants.AddressZero,
          ethers.utils.keccak256(ethers.utils.toUtf8Bytes(metadataIpfsHash))
        )
          .send({
            feeLimit: 100_000_000,
            callValue: 0,
            shouldPollResponse: false,
          })
          .then(() => {
            setCreateRedEnvelopeLoading(false);
            createUrlAndShow();
          });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <div className="choose_envelope_main_content">
        <div className="red_envelope_viewer_container">
          {redEnvelopeSelected === "default" && (
            <div className="red_envelope_default_container">
              <img src="/assets/images/red_envelope_default.png" alt="" />
            </div>
          )}
          {redEnvelopeSelected === "orange" && (
            <Lottie animationData={red_envelope_orange} style={defaultStyle} />
          )}
          {redEnvelopeSelected === "rich" && (
            <Lottie animationData={red_envelope_rich} style={defaultStyle} />
          )}
          {redEnvelopeSelected === "long" && (
            <Lottie animationData={red_envelope_long} style={defaultStyle} />
          )}
          {redEnvelopeSelected === "safe" && (
            <Lottie animationData={red_envelope_safe} style={defaultStyle} />
          )}
          <div className="buttons_container">
            {redEnvelopeSelected !== "default" && (
              <Button classStyleName="primary_button d-flex d-flex-v-center gap-5">
                支付{" " /*PAY */}
                <span className="bitcoin_symbol_card">
                  <img src="/assets/images/bitcoin_symbol.png" alt="" />1
                </span>{" "}
                使用當前紅包封面{/*Use current red envelope cover*/}
              </Button>
            )}
            {!allowanceTokenLoading && !sufficientAllowance ? (
              <Button
                handleOnClick={() => {
                  approveToken();
                }}
                text={
                  allowanceTokenLoading || approveTokenLoading
                    ? "加载中…" //LOADING…
                    : "APPROVE批准" //APPROVE
                }
                classStyleName="primary_button"
              />
            ) : (
              <Button
                handleOnClick={() => {
                  createRedEnvelope();
                }}
                text={
                  allowanceTokenLoading || createRedEnvelopeLoading
                    ? "加载中…" //LOADING…
                    : "使用默認紅包封面" //Use default red envelope cover
                }
                classStyleName="primary_button"
              />
            )}
          </div>
        </div>
        {/* CHOOSE ENVELOPE DESIGN SECTION TO V2 APP */}
        {/* <div className="choose_envelope_container">
      {ENVELOPES_STYLES.map((style) => {
        return (
          <div
            key={style.id}
            className={
              style.id + "_envelope_container style_envelope_container"
            }
            onClick={() =>
              document.getElementById(`envelope_${style.id}_button`).click()
            }
          >
            <Lottie animationData={style.animationId} style={style.styleId} />
            <div className="rectangle_shadow"></div>
            <button
              id={"envelope_" + style.id + "_button"}
              onClick={() => setRedEnvelopeSelected(style.id)}
            >
              {redEnvelopeSelected === style.id ? (
                <img
                  className="check_img"
                  src="/assets/images/checked_icon.png"
                  alt=""
                />
              ) : (
                <img
                  className="check_img"
                  src="/assets/images/unchecked_icon.png"
                  alt=""
                />
              )}{" "}
              <img src="/assets/images/bitcoin_symbol.png" alt="" />{" "}
              <span>1</span>
            </button>
          </div>
        );
      })}
    </div>
    <div className="white_bottom_space"></div> */}
      </div>
      <ReactModal isOpen={showModal} style={customStyleModal}>
        <p className="modal_text">{modalMessage}</p>
        <a
          className="modal_text_link mt-2"
          onClick={() => {
            navigator.clipboard.writeText(urlRedEnvelope);
            notifyCopiedLink();
          }}
          href="#"
        >
          点击此处复制网址{/**Click here to copy URL */}
        </a>
        <div className="terms_lose_container mt-5">
          <input
            type="checkbox"
            name=""
            id="termsLose"
            onChange={() => setReadTerms(!readTerms)}
          />
          <label className="modal_text" htmlFor="termsLose">
            我已阅读并理解如果我丢失了 URL, 此页面无法帮助我恢复它
          </label>
        </div>
        {/**I have read and understand if i lose the URL this page can't help me
        to recover it */}
        {warningToAccept && (
          <div className="alert alert-danger mt-2" role="alert">
            选中该框以继续
          </div>
        )}{" "}
        {/*CHECK THE BOX TO CONTINUE*/}
        <div className="buton_container_modal">
          <Button
            text={"接受"} //ACCEPT
            classStyleName={"primary_button"}
            handleOnClick={() =>
              readTerms ? handleSetStep("stepFive") : setWarningToAccept(true)
            }
          />
        </div>
      </ReactModal>
    </>
  );
};
