import "./button.scss";
export const Button = ({
  text,
  handleOnClick,
  classStyleName,
  selected = false,
  children,
}) => {
  return (
    <button
      onClick={handleOnClick}
      className={selected ? classStyleName + " selected" : classStyleName}
    >
      {text ? text : children}
    </button>
  );
};
