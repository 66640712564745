import "./step-five.scss";
import { Button } from "../button/button";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";

export const StepFive = ({ handleSetStep, handleSetLanternSelected }) => {
  const { connected } = useWallet;

  return (
    <div className="success_container">
      <img src="/assets/images/success_red_envelope.png" alt="" />
      <div className="button_container">
        <Button
          classStyleName={"primary_button"}
          text={"再發送一封紅包"}
          handleOnClick={() => {
            handleSetStep(connected ? "stepTwo" : "stepOne");
            handleSetLanternSelected("");
          }}
        />
      </div>
    </div>
  );
};
