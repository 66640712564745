import { ShowETHAddress } from "../../utils/show_eth_address";
import { WalletDropdown } from "../wallet-dropdown/wallet-dropdown";
import { useEffect, useState } from "react";
import "./header.scss";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";

export const Header = ({ step, handleSetStep }) => {
  const [showWalletDropdown, setShowWalletDropdown] = useState(false);
  const [hideTitle, setHideTitle] = useState(false);
  const { connected, address } = useWallet();

  useEffect(() => {
    const params = window.location.pathname.split("/");
    if (params.length === 3) {
      setHideTitle(true);
    }
  }, []);

  return (
    <div className="header_container">
      {showWalletDropdown && (
        <button
          id="hide-dropdown"
          className="hide_dropdown_button"
          onClick={() => setShowWalletDropdown(false)}
        ></button>
      )}
      <div className="navbar">
        <h2 className="logo-text">
          <img alt="Logo" src="/assets/images/logo.png"></img>
        </h2>
        {connected && (
          <>
            <button onClick={() => setShowWalletDropdown(!showWalletDropdown)}>
              <img src="/assets/images/wallet_icon.png" alt="" />
              <span>
                {ShowETHAddress(address)}{" "}
                <img src="/assets/images/arrow_down.png" alt="" />
              </span>
            </button>

            <WalletDropdown open={showWalletDropdown} />
          </>
        )}
      </div>
      <div className="app_banner_container center">
        <img
          className="app_banner"
          src="/assets/images/app_banner.png"
          alt=""
        />
      </div>

      {step !== "stepFive" && !hideTitle && (
        <div className="row title_container">
          <div className="col-lg-4 col-md-3 col-3">
            {step === "stepThree" && (
              <button
                className="back_icon_container"
                onClick={() => handleSetStep("stepTwo")}
              >
                <img
                  className="back_icon"
                  src="/assets/images/back_icon.png"
                  alt=""
                />
                <span>返回</span>
              </button>
            )}
          </div>
          <div className="col-lg-4 col-md-6 col-6">
            <div className="main_title">
              <img
                className="banner_frame_top"
                src="/assets/images/title_frame.png"
                alt=""
              />
              {
                step === "stepOne" && (
                  <h1 className="title-main">登陸發紅包</h1>
                ) /* LOG IN TO SEND RED ENVELOPES */
              }
              {
                step === "stepTwo" && (
                  <h1 className="title-main">選擇紅包金额</h1>
                ) /* SELECT RED ENVELOPE AMOUNT */
              }
              {
                step === "stepThree" && (
                  <h1 className="title-main">添加信息</h1>
                ) /* ADD INFO */
              }
              {
                step === "stepFour" && (
                  <h1 className="title-main">選擇紅包封面</h1>
                ) /* CHOOSE RED ENVELOPE COVER */
              }
              <img
                className="banner_frame_bottom"
                src="/assets/images/title_frame.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-3 col-3">
            {/*
            <div className="drum_container">
              <img className="drum" src="/assets/images/drum.png" alt="" />
              <img
                className="drumstick"
                src="/assets/images/drumstick.png"
                alt=""
              />
              <p>幸运大转盘</p>
            </div>
             */}
          </div>
        </div>
      )}
    </div>
  );
};
