export const getThumbnailFromFile = (file, i) => {
  return file.type.substring(0, 5) === "image"
    ? URL.createObjectURL(file)
    : getThumbFromVideo(file, i);
};

const snapImageFromVideo = (video, url, i, name) => {
  const canvas = document.createElement("canvas");
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
  const image = canvas.toDataURL();
  const success = image.length > 100000;

  if (success) {
    const img = document.getElementById(`${name + i}`);
    img.src = image;
    URL.revokeObjectURL(url);
  }

  return success;
};

const getThumbFromVideo = (file, i) => {
  const fileReader = new FileReader();

  fileReader.onload = () => {
    const blob = new Blob([fileReader.result], { type: file.type });
    const url = URL.createObjectURL(blob);
    const video = document.createElement("video");

    const timeupdate = () => {
      if (snapImageFromVideo(video, url, i, file.name)) {
        video.removeEventListener("timeupdate", timeupdate);
        video.pause();
      }
    };

    video.addEventListener("loadeddata", () => {
      if (snapImageFromVideo(video, url, i, file.name)) {
        video.removeEventListener("timeupdate", timeupdate);
      }
    });

    video.addEventListener("timeupdate", timeupdate);
    video.preload = "metadata";
    video.src = url;
    video.muted = true;
    video.playsInline = true;
    video.play();
  };

  fileReader.readAsArrayBuffer(file);
};
