import { formatSeconds } from "./formatSeconds";

export const getVideoDuration = (file) => {
  const video = document.createElement("video");
  video.preload = "metadata";
  video.onloadedmetadata = function () {
    document.getElementById("video_duration").textContent = formatSeconds(
      video.duration
    );
    window.URL.revokeObjectURL(video.src);
  };

  video.src = URL.createObjectURL(file);
};
